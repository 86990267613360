import React from 'react'
import styled from 'styled-components'
import CodeIcon from '@mui/icons-material/Code';
import PieChartIcon from '@mui/icons-material/PieChart';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import BadgeIcon from '@mui/icons-material/Badge';
import CloudIcon from '@mui/icons-material/Cloud';

const ServiceListing = () => {
    var title = "Nuestros Servicios"

    return (
        <ServiceListingStyled>
            <section className="services" id="services">
                <div className="container">
                    <h1>{title}</h1>
                    <div className="row">
                        
                        <div className="service-item">
                            <CodeIcon className="i" />
                            <h2>Diseño Web</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, minima dicta asperiores officia eligendi reprehenderit nihil ratione dolore impedit illum.</p>
                        </div>

                        {/* <div className="service-item">
                            <CloudIcon className="i" />
                            <h2>Respaldo en la nube</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, minima dicta asperiores officia eligendi reprehenderit nihil ratione dolore impedit illum.</p>
                        </div>

                        <div className="service-item">
                            <PieChartIcon className="i" />
                            <h2>Inteligencia de Negocios</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, minima dicta asperiores officia eligendi reprehenderit nihil ratione dolore impedit illum.</p>
                        </div>

                        <div className="service-item">
                            <PhoneAndroidIcon className="i" />
                            <h2>Desarrollo de aplicaciones</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, minima dicta asperiores officia eligendi reprehenderit nihil ratione dolore impedit illum.</p>
                        </div>

                        <div className="service-item">
                            <BadgeIcon className="i" />
                            <h2>Sistema Nómima</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, minima dicta asperiores officia eligendi reprehenderit nihil ratione dolore impedit illum.</p>
                        </div> */}

                    </div>
                </div>
            </section>
        </ServiceListingStyled>
    )

}

const ServiceListingStyled = styled.div `
.container {
    width: 100%;
    height: auto; //100vh;
    padding: 0 8%;
    margin-bottom: 50px;
}

.container h1 {
    text-align: center;
    padding-top: 10%;
    margin-bottom: 60px;
    font-weight: 600;
    position: relative;
}

.container h1::after {
    position: absolute;
    content: "";
    width: 100px;
    height: 5px;
    background-color: #303ef7;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
}

.row {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px, 1fr));
    grid-gap: 30px;
}

.service-item {
    text-align: center;
    padding: 25px 10px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    background-color: transparent;
    transition: transform 0.5s, background 0.5s;
}

.service-item .i {
    font-size: 40px;
    margin-bottom: 10px;
    color: #303ef7;
}

.service-item:hover .i {
    color: #fff;
}   


.service-item h2 {
    font-weight: 600;
    margin-bottom: 8px;
}

.service-item:hover {
    background-color: #303ef7;
    color:#fff;
    transform: scale(1.05);
}

`
export default ServiceListing