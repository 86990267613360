import Navbar from './components/navbar/Navbar'
import Header from './components/header/Header'
import JobListing from './components/jobListing/JobListing'
import ServiceListing from './components/serviceListing/ServiceListing'
import Footer from './components/footer/Footer'

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      {/* <JobListing /> */}
      <ServiceListing />
      <Footer />
     </div>
  );
}

export default App;
