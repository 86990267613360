import React from 'react'
import styled from 'styled-components'

const Navbar = () => {

    return (
        <NavbarStyled>
            <nav className="navbar">
                <div className="navbar-logo">
                    <a href="#">
                        <img src="/images/logo-48x300.png" alt="" />
                    </a>
                </div>

                <div className="navbar-menu">
                    <a href="#services">Servicios</a>
                    {/* <a href="#jobs">Empleos</a>
                    <a href="#companies">Compañías</a>
                    <a href="#testimoni">Testimonios</a>
                    <a href="#blog">Blogs</a>
                    <a href="#">Acceder</a> */}
                </div>

                <div className="menu-toggle">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </nav>
        </NavbarStyled>
    )
}

const NavbarStyled = styled.section`
    .navbar {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        top: 0;
        left: 0;
        padding: 1rem 2.5rem;
        color: var(--whiteColor);
        background: transparent; 
        z-index: 1000;
        position: fixed;
    }

    .navbar-logo > img {
        
    }

    .navbar-logo > a {
        letter-spacing: 2.5; 
        text-decoration: none;
        color: var(--primaryColor);
    } 

    .navbar-menu {
        width:  auto;//600px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-menu > a {
        color: var(--primaryColor);
        text-decoration: none;
    }

    .menu-toggle {
        /* display: block; */
        display: none; 
    }

    .menu-toggle .bar {
        width: 15px;
        height: 2px;
        margin: 3px auto;
        transition: all 0.3 ease-in-out;
        background-color: var(--primaryColor);
        cursor: pointer;
    }

/* mobile screen */
@media screen and (max-width: 600px) {
    .menu-toggle,
    .bar {
        display: block;
        z-index: 3;
    }

    .menu-toggle.is-active .bar:nth-child(2) {
        opacity: 0;
    }

    .menu-toggle.is-active .bar:nth-child(1) {
        transform: translateY(5px) rotate(4deg);
    }
    
    .menu-toggle.is-active .bar:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
    }

    .navbar-menu {
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        text-align: center;
        background-color: var(--blackColor);
        display: none;
        padding: 5rem;
    }

    .navbar-menu.active {
        display: flex;
    }

    .navbar a {
        color: var(--whiteColor);
        padding: 1.5rem;
    }

    .header-title {
        font-size: 38px;
    }
}





`

export default Navbar