import React from 'react'
import styled from 'styled-components'

const JobListing = () => {
    return (
        <JobListingStyled>
            <div className="job-list" id="jobs">

                <div className="job-card">
                    <div className="job-name">
                        <img src="tesla-motors.svg" alt="" class="job-profile" />
                        <div className="job-detail">
                            <h4>Tesla Motors</h4>
                            <h3>Ingeniero Mecánico</h3>
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                        </div>
                    </div>
                    <div className="job-label">
                        <a href="#" className="label-a">HTML</a>
                        <a href="#" className="label-b">CSS</a>
                        <a href="#" className="label-c">JavaScript</a>
                    </div>
                    <div className="job-posted">
                        publicado hace 2 minutos
                    </div>
                </div>
                    
                <div className="job-card">
                    <div className="job-name">
                        <img src="coca-cola-1.svg" alt="" className="job-profile" />
                        <div className="job-detail">
                            <h4>Tesla Motors</h4>
                            <h3>Ingeniero Mecánico</h3>
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                        </div>
                    </div>
                    <div className="job-label">
                        <a href="#" className="label-a">HTML</a>
                        <a href="#" className="label-b">CSS</a>
                        <a href="#" className="label-c">JavaScript</a>
                    </div>
                    <div className="job-posted">
                        publicado hace 2 minutos
                    </div>
                </div>
                    
                <div className="job-card">
                    <div className="job-name">
                        <img src="facebook-3.svg" alt="" className="job-profile" />
                        <div className="job-detail">
                            <h4>Tesla Motors</h4>
                            <h3>Ingeniero Mecánico</h3>
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                        </div>
                    </div>
                    <div className="job-label">
                        <a href="#" className="label-a">HTML</a>
                        <a href="#" className="label-b">CSS</a>
                        <a href="#" className="label-c">JavaScript</a>
                    </div>
                    <div className="job-posted">
                        publicado hace 2 minutos
                    </div>
                </div>
                    
                <div className="job-card">
                    <div className="job-name">
                        <img src="uber-15.svg" alt="" className="job-profile"/>
                        <div className="job-detail">
                            <h4>Tesla Motors</h4>
                            <h3>Ingeniero Mecánico</h3>
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                        </div>
                    </div>
                    <div className="job-label">
                        <a href="#" className="label-a">HTML</a>
                        <a href="#" className="label-b">CSS</a>
                        <a href="#" className="label-c">JavaScript</a>
                    </div>
                    <div className="job-posted">
                        publicado hace 2 minutos
                    </div>
                </div>
                    
                <button className="job-more">Ver más</button>
                    
            </div>
        </JobListingStyled>
    )
}

const JobListingStyled = styled.section `
    .job-list {
        margin: 5%;
        padding: 1rem;
        background-color: var(--greyColor);
    }

    .job-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        margin: 1rem;
        min-height: 100px;
        background-color: var(--whiteColor);
        box-shadow: rbga(0,0,0,0.16), 0 1px 4px;
        cursor: pointer;
    }

    .job-card:hover {
        box-shadow: rbga(0,0,0,0.16), 0 4px 12px;
    }

    .job-name {
        display: flex;
    }

    .job-profile {
        width: 60px;
        height: 60px;
        margin: 0.5rem;
    }

    .job-detail {
        padding: 0 10px;
    }

    .job-detail > h4 {
        color: var(--primaryColor);
        font-size: 0.9rem;
    }

    .job-detail > p {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .job-label {
        display: flex;
        flex-wrap: wrap;
    }

    .job-label > a {
        padding: 0 1rem;
        margin: 0.2rem;
        color: var(--whiteColor);
        text-decoration: none;
        border-radius: 20px;
        font-size: 0.8rem;
    }

    .label-a {
        background-color: var(--yellowColor);
    }

    .label-b {
        background-color: var(--primaryColor);
    }

    .label-c {
        background-color: var(--blackColor);
    }

    .job-posted {
        font-size: 0.8rem;
    }

    .job-more {
        display: block;
        padding: 0.3rem 2rem;
        margin: 20px auto;
        width: 250px;
        background-color: transparent;
        color: var(--primaryColor);
        border: 1px solid var(--primaryColor);
        border-radius: 20px;
        cursor: pointer;
    }

    .job-more:hover {
        background-color: var(--primaryColor);
        color: var(--whiteColor);
    }   
`

export default JobListing