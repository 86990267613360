import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle `
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --primaryColor: #0076de;
    --whiteColor: #fff;
    --blackColor: #333;
    --greyColor: #f6f8fa;
    --labelColor: #64d1f5;
    --yellowColor: #ffd723;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    font-family: 'Roboto', sans-serif;
}

body {
    width: 100%;
    /* height: auto; */
    height: 100vh;
}

h1 {
    line-height: 1.5;
}


@media screen and (max-width: 600px) {
    .menu-toggle,
    .bar {
        display: block;
        z-index: 3;
    }

    .menu-toggle.is-active .bar:nth-child(2) {
        opacity: 0;
    }

    .menu-toggle.is-active .bar:nth-child(1) {
        transform: translateY(5px) rotate(4deg);
    }
    
    .menu-toggle.is-active .bar:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
    }

    .navbar-menu {
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        text-align: center;
        background-color: var(--blackColor);
        display: none;
        padding: 5rem;
    }

    .navbar-menu.active {
        display: flex;
    }

    .navbar a {
        color: var(--whiteColor);
        padding: 1.5rem;
    }

    .header-title {
        font-size: 38px;
    }
}


`

export default GlobalStyle;