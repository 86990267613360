import React from 'react'
import styled from 'styled-components'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';

const Footer = () => {
    return (
        <FooterStyled>
            <footer>
                <div className="container">
                    <div className="sec aboutus">
                        <h2>TecnoEmpleos</h2>
                        <p>La primera bolsa de empleos tecnológicos en línea de la República Dominicana.</p>
                        <ul className="sci">
                            <li><a href="https://www.linkedin.com/company/11335142/" target="_blank" alt=""><LinkedInIcon className="i"/></a></li>
                            <li><a href="https://t.me/tecnoempleosrd" target="_blank" alt=""><TelegramIcon className="i"/></a></li>
                            <li><a href="https://www.facebook.com/TecnoEmpleosRD/" target="_blank" alt=""><FacebookIcon className="i"/></a></li>
                            <li><a href="https://twitter.com/TecnoEmpleosRD" target="_blank" alt=""><TwitterIcon className="i"/></a></li>
                        </ul>
                    </div>

                    <div className="sec quick-links">
                        <h2>Vínculos</h2>
                        <ul>
                            <li><a href="#" alt="">Preguntas frecuentes</a></li>
                            <li><a href="#" alt="">Política de privacidad</a></li>
                            <li><a href="#" alt="">Términos y condiciones</a></li>
                        </ul>
                    </div>

                    <div className="sec contact">
                        <h2>Contacto</h2>
                        <ul className="info">
                            <li>
                                <p><a href="mailto:info@tecnoempleos.com.do">info@tecnoempleos.com.do</a></p>
                            </li>
                        </ul>
                    </div>

                </div>
            </footer>


            <div className="copyright">
                <p>&copy; TecnoEmpleos {new Date().getFullYear()}</p>
            </div>
        </FooterStyled>
    )
}

const FooterStyled = styled.div `
footer {
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 100px;
    background: var(--primaryColor); // #111;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}

footer .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
 
}

footer .container .sec  {
    margin-right: 30px;
}

footer .container .sec.aboutus {
    width:  40%;
}

footer h2{
    position: relative;
    color: #fff;
    font-weight: 500;
    margin-bottom: 15px;
}

footer .container h2:before{
    position: absolute;
    content: "";
    color: #fff;
    left: 0;
    bottom:-5px;
    width: 50px;
    height: 2px;
    background: #f00;;
}

footer p {
    color: #999;
}

.sci    {
    margin-top: 20px;
    display: flex;
}

.sci li {
    list-style: none;
}

.sci li a {
    display: inline-block;
    background: #fff;// #222;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    text-decoration: none;
    border-radius: 4px;

    color: var(--primaryColor);
    font-size: 30px;
}

.sci li a:hover {
    background: var(--primaryColor);
    color: white;
}


.quick-links {
    position: relative;
    width: 25%;
}

.quick-links ul li {
    list-style: none;
}

.quick-links ul li a {
    color: #999;
    text-decoration: none;
    margin-bottom: 10px;
    display: inline-block;
}

.quick-links ul li a:hover {
    color: black;
}

.contact {
    width: calc(25% - 60px);
    margin-right: 0 !important;
}

.contact .info {
    position: relative;
}

.contact ul {
    list-style: none;
}

.contact .info li {
    display: flex;
    margin-bottom: 16px;
}

.contact .info li span:nth-child(1) {
    color: #fff;
    font-size: 20px;
    margin-right: 10px;
}

.contact .info li span {
    color: #999;
}

.contact .info li a {
    color: #999;
    text-decoration: none;
}

.copyright {
    width: 100%;
    background: #fff;//#615d5d;
    padding: 8px 100px;
    text-align: center;
    color: var(--primaryColor);//#aaa;
    //font-weight: bold;
    font-size: 0.8rem;
}

    /* repsonsive */
@media (max-width: 991px) {
    footer {
        padding: 40px;
    }

    footer .container {
        flex-direction: column;
    }

    footer .container .sec {
        margin-right: 0;    
        margin-bottom: 40px;
    }

    footer .container .sec.aboutus .quick-links {
        width: 100%;
    }
}
`

export default Footer