import React from 'react';
import styled from 'styled-components'

const Header = () => {
    return (
        <HeaderStyled>
            <div className="header">
                <h1 className="header-title">
                    ENCUENTRA TU 
                    <br/><span>TRABAJO PERFECTO </span>
                    <br/>FACILMENTE
                </h1>
            </div>           
        </HeaderStyled>
    )
}

const HeaderStyled = styled.section `
    .header {
        width: 100%;
        height: 400px;
        /* background-color: yellow; */
        background-image: url('images/header-background.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .header-title {
        position: absolute;
        top: 7rem;
        left: 3rem;
        letter-spacing: 3;
        line-height: 3rem;
        font-weight: bolder;
        font-size: 52px;
        color: var(--whiteColor);
        text-shadow: 4px 2px 0px rgba(0,0,0,0.2);
    }

    .header-title > span {
        color: var(--primaryColor);
    }
`


export default Header